import { Button } from "@mui/material";
import React from "react";

export function PrimaryButton({ btnText, onClickBtn }) {
  return (
    <Button
      onClick={onClickBtn}
      sx={{ mx: "10px", p: "10px 16px", fontWeight: 600, fontSize: "14px" }}
    >
      {btnText}
    </Button>
  );
}

export function PrimaryBorderButton({ btnText }) {
  return (
    <Button
      sx={{
        p: "10px 16px",
        border: "1px solid #DB2BBB",
        color: "#DB2BBB",
        background: "transparent",
        fontWeight: 600,
        fontSize: "14px",
      }}
    >
      {btnText}
    </Button>
  );
}

export function SecondaryBorderButton({ btnText }) {
  return (
    <Button
      sx={{
        p: "10px 16px",
        background: "transparent",
        fontWeight: 600,
        fontSize: "14px",
        color: "#101828",
        border: "1px solid #D0D5DD",
        "&:hover": { backgroundColor: "#D0D5DD" },
      }}
      onClick={() => setViewAssign(dummyData.length)}
    >
      View All
    </Button>
  );
}

export function PlanButton({ btnText }) {
  return (
    <Button
      sx={{
        mx: "10px",
        p: "10px 16px",
        fontWeight: 600,
        background: "#000000",
        color: "#fff",
        fontSize: "14px",
        "&:hover": { backgroundColor: "#D0D5DD" },
      }}
    >
      Buy Now
    </Button>
  );
}
