const featureList = [
  {
    imgUrl: "/assets/images/SearchHero.svg",
    text: "Access Content Anywhere, Anytime",
    color: "#063755",
  },
  {
    imgUrl: "/assets/images/salesman.svg",
    text: `Acquiring Expertise from Professionals`,
    color: "#DB2BBB",
  },
  {
    imgUrl: "/assets/images/bookHero.svg",
    text: "More Than Just Online Assignments",
    color: "#DBA32B",
  },
  {
    imgUrl: "/assets/images/CertiHero.svg",
    text: "Earning your certification is a valuable pursuit",
    color: "#2BDB4B",
  },
];

export { featureList };
