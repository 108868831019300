import { Box, Grid, Typography } from "@mui/material";
import Image from "next/image";
import React from "react";

export default function FeaturesCard({ feature }) {
  return (
    <Grid lg={3} sm={6} xs={12} item={true}>
      <Box
        sx={{
          backgroundColor: `${feature.color}`,
          color: "#fff",
          textAlign: "center",
          mr: { lg: "60px", xs: "0px" },
          mt: "100px",
          boxShadow: " 6px 8px 14px -5px rgba(0, 0, 0, 0.54)",
          position: "relative",
          zIndex: 2,
          height: { lg: "250px", xs: "200px" },
        }}
      >
        <Box sx={{ position: "absolute" }}>
          <Image
            src={feature.imgUrl}
            width={100}
            height={100}
            alt="search feature"
            style={{
              width: "100%",
              height: "auto",
              position: "relative",
              top: -50,
              left: "calc(0% + 100px)",
            }}
          />
        </Box>
        <Typography
          variant="body1"
          sx={{
            p: { lg: "150px 50px 50px 50px", xs: "100px 0px" },
            color: "#fff",
            fontSize: "20px",
            fontWeight: 600,
          }}
        >
          {feature.text}
        </Typography>
      </Box>
    </Grid>
  );
}
