import { socialLink } from "@/utils/socialLink";
import { List, ListItem, Typography } from "@mui/material";
import Image from "next/image";
import React from "react";

const SocialIcon = () => {
  const socialIcon = [
    {
      id: 1,
      name: "ebrainee Facebook",
      url: "https://www.facebook.com/",
      icon: "/assets/icon/fb.svg",
      bg: "#3b5998",
    },
    {
      id: 2,
      name: "ebrainee Instagram",
      url: "https://www.instagram.com/",
      icon: "/assets/icon/insta.svg",
      bg: "#e4405f",
    },
    {
      id: 3,
      name: "ebrainee Twitter",
      url: "https://twitter.com/",
      icon: "/assets/icon/twitter.svg",
      bg: "#55acee",
    },
    {
      id: 4,
      name: "ebrainee Youtube",
      url: "https://www.youtube.com",
      icon: "/assets/icon/yt.svg",
      bg: "#c4302b",
    },
    {
      id: 5,
      name: "ebrainee Tiktok",
      url: "https://www.tiktok.com/",
      icon: "/assets/icon/tiktok.svg",
      bg: "#000",
    },
  ];
  return (
    <List
      sx={{
        background: "#fff",
        boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)",
        borderRadius: "10px",
      }}
    >
      {socialLink.map((item) => (
        <ListItem
          key={item.id}
          sx={{
            p: 0,
            m: 0,
          }}
        >
          <a
            href={item.url}
            style={{
              padding: "10px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Image src={item.icon} width={25} height={25} alt={item.name} />
          </a>
        </ListItem>
      ))}
    </List>
  );
};

export default SocialIcon;
