import Image from "next/image";
import { Box, Grid, Typography } from "@mui/material";

import styles from "../../app/page.module.css";
import { PrimaryBorderButton } from "../Common/CustomButtons";
import FeaturesCard from "../Common/FeaturesCard";
import { featureList } from "./../../utils/FeaturesData";
import useWebsiteInfoStore from "@/utils/store/WebsiteInfoStore";
import ViewTextBox from "../Common/ViewTextBox";
export default function HeroSection() {
  const { websiteInfoData } = useWebsiteInfoStore();

  return (
    <Box>
      <Grid container>
        <Grid lg={6} item={true}>
          {/* <Typography
            variant="h5"
            sx={{
              mt: { lg: "100px", xs: "0px" },
              color: "#DB2BBB",
              fontSize: "24px",
              fontWeight: 600,
            }}
          >
            Learn Today And
          </Typography> */}
          {websiteInfoData ? (
            <Box>
              <Typography
                variant="h1"
                sx={{
                  color: "#3A3A3A",
                  fontSize: { xs: "40px", lg: "60px" },
                  fontWeight: 700,
                  textShadow: "0px 4px 13px rgba(0, 0, 0, 0.25)",
                  marginBottom: "30px",
                  mt: { xs: 10 },
                }}
              >
                {websiteInfoData.websiteTitle}
               
                {/* <Typography
                  variant="body1"
                  sx={{
                    color: "#3A3A3A",
                    fontSize: "18px",
                    fontWeight: 500,
                    textAlign: "left",
                    mb: "15px",
                    mr: { lg: "50px" },
                  }}
                >
                  {websiteInfoData.websiteHomeDescription1}
                </Typography> */}
                {/* <Typography
               variant="span"
               sx={{
                 background: "#FF9B26",
                 borderRadius: "5px",
                 ml: "10px",
                 pr: "10px",
                 px: "5px",
               }}
             >
               future
             </Typography> */}
              </Typography>
              <Box>
                  <ViewTextBox
                  value={websiteInfoData?.websiteHomeDescription1 && JSON.parse(websiteInfoData?.websiteHomeDescription1)}
                  ></ViewTextBox>
                </Box>
            </Box>
          ) : (
            <Box>
              <Typography
                variant="h1"
                sx={{
                  color: "#3A3A3A",
                  fontSize: { xs: "40px", lg: "60px" },
                  fontWeight: 700,
                  textShadow: "0px 4px 13px rgba(0, 0, 0, 0.25)",
                  marginBottom: "30px",
                  mt: { xs: 10 },
                }}
              >
                e Brainee Learning
                <Typography
                  variant="span"
                  sx={{
                    background: "#FF9B26",
                    borderRadius: "5px",
                    ml: "10px",
                    pr: "10px",
                    px: "5px",
                  }}
                >
                  Management System
                </Typography>
              </Typography>
              <Typography
                variant="body1"
                sx={{
                  color: "#3A3A3A",
                  fontSize: "18px",
                  fontWeight: 600,
                  mb: "15px",
                  mr: { lg: "40px" },
                }}
              >
                "The Stellar Companion: Your Trusted Partner in the World of
                Learning Management Systems"
              </Typography>
              <Typography
                variant="body1"
                sx={{
                  color: "#3A3A3A",
                  fontSize: "18px",
                  fontWeight: 500,
                  textAlign: "left",
                  mb: "15px",
                  mr: { lg: "50px" },
                }}
              >
                <br></br>At e Brainee, our dedication is centered on empowering
                organisations to thrive and grow. By prioritising product
                innovation and delivering exceptional service, our Learning
                Management Systems (LMS) software has empowered ambitious
                organisations, providing them with the flexibility to
                concentrate on their core objectives
              </Typography>
              <Typography
                variant="body1"
                sx={{
                  color: "#3A3A3A",
                  fontSize: "18px",
                  fontWeight: 600,
                  mb: "15px",
                  mr: { lg: "40px" },
                }}
              >
                Discover the Benefits of e Brainee Learning Management System
              </Typography>
              <Typography
                variant="body1"
                sx={{
                  color: "#4c4e52",
                  fontSize: "18px",

                  textAlign: "left",
                  mb: "15px",
                  mr: { lg: "50px" },
                }}
              >
                - Streamline your operations to enhance productivity and unlock
                your full potential
              </Typography>
              <Typography
                variant="body1"
                sx={{
                  color: "#4c4e52",
                  fontSize: "18px",

                  textAlign: "left",
                  mb: "15px",
                  mr: { lg: "50px" },
                }}
              >
                - Tailored solutions for any business environment and suitable
                for Lecturers/Trainers/Teachers
              </Typography>
              <Typography
                variant="body1"
                sx={{
                  color: "#4c4e52",
                  fontSize: "18px",

                  textAlign: "left",
                  mb: "15px",
                  mr: { lg: "50px" },
                }}
              >
                - Our professionals are dedicated to developing,
                overseeing, and maintaining your IT solutions
              </Typography>
            </Box>
          )}
          {/* <PrimaryBorderButton btnText="Learn more" /> */}
        </Grid>
        <Grid
          lg={6}
          item={true}
          sx={{
            marginTop: 5,

            display: { xs: "none", lg: "block" },
          }}
        >
          <Box>
            {/* <Image
              src="/assets/images/offer.jpeg"
              alt="ebrainee"
              width={90}
              height={90}
              style={{ width: "80%", height: "100%", marginLeft: 50 }}
            /> */}
          </Box>
          <Image
            src="/assets/images/HeaderSection.svg"
            alt="ebrainee"
            width={100}
            height={100}
            style={{ width: "100%", height: "100%" }}
          />
        </Grid>
      </Grid>
      <Box
        sx={{
          width: "100%",
          height: "100%",
          overflow: "hidden",
        }}
      >
        <Box
          sx={{
            position: "absolute",
            top: "800px",
            left: "500px",
            zIndex: 1,
          }}
        >
          <Image
            src="/assets/images/dotedcureveline1.svg"
            alt="ebrainee stars"
            className={styles.dotCurve}
            width={100}
            height={100}
            style={{
              width: "100%",
              height: "100%",
            }}
          />
        </Box>
        <Typography
          variant="h1"
          sx={{
            color: "#3A3A3A",
            position: "relative",
            mt: { lg: "40px", xs: "80px" },
            fontSize: "48px",
            fontWeight: 700,
            textShadow: "0px 4px 13px rgba(0, 0, 0, 0.25)",
            my: "30px",
            textAlign: "center",
            zIndex: 2,
          }}
        >
          Highlights Of Our Uniqueness
        </Typography>

        <Box>
          <Grid container>
            {featureList.map((feature, index) => {
              return <FeaturesCard key={index} feature={feature} />;
            })}
          </Grid>
        </Box>
      </Box>
    </Box>
  );
}
