import React, { useState } from "react";
import dynamic from "next/dynamic";
import { Box } from "@mui/material";


function ViewTextBox({ value }) {
  const previewStyle = {
    margin:"10px",
    padding: '10px',
    minHeight: '200px',
  
  };

  return (
    <Box
    style={previewStyle} 
    dangerouslySetInnerHTML={{ __html:value  }} // Render HTML safely
  />
  );
}

export default ViewTextBox;
