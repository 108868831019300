const socialLink = [
  {
    title: "Facebook",
    id: 1,
    name: "ebrainee Facebook",
    url: "https://www.facebook.com/profile.php?id=100092502278615",
    icon: "/assets/Icon/fb.svg",
    icon2: "/assets/Icon/fb2.svg",
  },
  {
    title: "Instagram",
    id: 2,
    name: "ebrainee Instagram",
    url: "https://www.linkedin.com/in/ebrainee-education-6009062aa/",
    icon: "/assets/Icon/insta.svg",
    icon2: "/assets/Icon/insta2.svg",
  },
  {
    title: "Twitter",
    id: 3,
    name: "ebrainee Twitter",
    url: "https://twitter.com/EBrainee15382",
    icon: "/assets/Icon/twitter.svg",
    icon2: "/assets/Icon/twitter2.svg",
  },
  {
    title: "Youtube",
    id: 4,
    name: "ebrainee Youtube",
    url: "https://www.youtube.com/@EBraineeEducation",
    icon: "/assets/Icon/yt.svg",
    icon2: "/assets/Icon/yt2.svg",
  },
  {
    title: "Tiktok",
    id: 5,
    name: "ebrainee Tiktok",
    url: "https://www.tiktok.com/@ebraineeeducation",
    icon: "/assets/Icon/tiktok.svg",
    icon2: "/assets/Icon/tiktok3.svg",
  },
  {
    title: "Pinterest",
    id: 6,
    name: "ebrainee Pinterest",
    url: "https://www.pinterest.co.uk/ebraineee/",
    icon: "/assets/Icon/pininterest3.svg",
    icon2: "/assets/Icon/pininterest2.svg",
  },
];

export { socialLink };
