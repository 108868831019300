"use client";
import React from "react";
import dynamic from "next/dynamic";

import HeroSection from "@/components/Home/HeroSection";

import { theme } from "@/components/theme";
import { Box, Button, ThemeProvider, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import SocialIcon from "@/components/Home/SocialIcon";
import Link from "next/link";
import useWebsiteInfoStore from "@/utils/store/WebsiteInfoStore";
import Navbar from "@/components/Navbar";

const Footer = dynamic(() => import("@/components/Footer"));

const BlogSection = dynamic(() => import("@/components/Home/BlogSection"));

const Pricing = dynamic(() => import("@/components/Home/Pricing"));

const HeroSection2 = dynamic(() => import("@/components/Home/HeroSection2"));

const ExploreProgram = dynamic(() =>
  import("@/components/Home/ExploreProgram")
);

export default function Home() {
  const [displayCookies, setDisplayCookies] = useState(true);
  const [showSocialIcon, setShowSocialIcon] = useState(false); // Initially, set it to false

  const [hostname, setHostname] = useState();
  const [origin, setOrigin] = useState();

  const handleAccept = () => {
    setDisplayCookies(false);
  };

  // Function to show the social icon with a delay
  const showSocialIconWithDelay = () => {
    setTimeout(() => {
      setShowSocialIcon(true);
    }, 500); // Adjust the delay as needed (e.g., 1000ms = 1 second)
  };

  useEffect(() => {
    // Show the social icon with a delay on first load
    showSocialIconWithDelay();

    // Add an event listener to track scroll position
    const handleScroll = () => {
      const scrollY = window.scrollY || window.pageYOffset;
      // Check if the user has scrolled down 100vh
      if (scrollY >= window.innerHeight * 1.5) {
        setShowSocialIcon(false); // Hide the social icon
      } else {
        setShowSocialIcon(true); // Show the social icon
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      // Remove the event listener when the component unmounts
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    console.log(window.location);

    if (typeof window !== "undefined") {
      setHostname(window.location.hostname);
      setOrigin(window.location.origin);
    }
  }, []);

  return (
    <main>
      <ThemeProvider theme={theme}>
        <Navbar />

        <Box sx={{ m: { lg: "80px 100px", xs: "20px" }, marginTop: 0 }}>
          <HeroSection />
        </Box>
        {hostname &&
          (hostname?.includes("www.ebrainee.com") ||
            hostname?.includes("www.e-brainee.com") ||
            hostname?.includes("www.localhost")) && (
        <Box
          sx={{
            background: "#063755",
            m: { lg: "120px 0px", xs: "80px 0px" },
            color: "#fff",
            zIndex: 2,
            position: "relative",
          }}>
          <HeroSection2 />
        </Box>)}
        <Box
          sx={{
            m: { lg: "120px 100px", xs: "80px 20px" },
            color: "#fff",
          }}>
          <ExploreProgram />
        </Box>

        {hostname &&
          (hostname?.includes("www.ebrainee.com") ||
            hostname?.includes("www.e-brainee.com") ||
            hostname?.includes("www.localhost")) && (
            <Box
              sx={{
                background: "#DB2BBB",
                m: { lg: "120px 0px", xs: "80px 0px" },
                color: "#fff",
                zIndex: 2,
                position: "relative",
              }}>
              <Pricing />
            </Box>
          )}

        {/* <Box
          sx={{
            m: { lg: "-100px 100px 120px 100px", xs: "-150px 20px 80px 20px" },
            color: "#fff",
          }}
        >
          <Testimonial />
        </Box> */}

        {/* <Box
          sx={{
            mx: { lg: "120px", xs: "20px" },
          }}
        >
          <Tutor />
        </Box> */}
        <Box
          sx={{
            m: { lg: "120px 100px", xs: "80px 20px" },
          }}>
          <BlogSection />
        </Box>
        <Footer />
      </ThemeProvider>
      <Box
        sx={{
          position: "fixed",
          top: { lg: "25%", xs: "25%" },
          right: { lg: "10px", xs: "10px" },
          zIndex: 100,
          opacity: showSocialIcon ? 1 : 0, // Use opacity to control visibility
          transform: showSocialIcon ? "scale(1)" : "scale(0.8)", // Use transform for the popup effect
          transition: "opacity 0.3s ease, transform 0.3s ease", // CSS transition
        }}>
        <SocialIcon />
      </Box>
      <Box
        sx={{
          position: "fixed",
          display: displayCookies ? "block" : "none",
          bottom: { lg: "20px", xs: "10px" },
          right: { lg: "20px", xs: "10px" },
          width: { lg: "400px", xs: "250px" },
          background: "#fff",
          boxShadow: " 0 6px 40px rgba(0,0,0,.3)",
          borderRadius: "10px",
          zIndex: 100,
          p: 2,
        }}>
        <Typography variant="body1" sx={{ fontWeight: 500, color: "#3A3A3A" }}>
          Cookies and Privacy
        </Typography>
        <Typography variant="body1" sx={{ color: "#3A3A3A" }}>
          This website uses cookies to ensure you to get the best experience on
          our website
        </Typography>
        <Box sx={{ mt: 1 }}>
          <Button
            onClick={handleAccept}
            sx={{
              p: "8px 16px",
              fontWeight: 600,
              fontSize: "11px",
              background: "#DB2BBB",
              color: "#fff",
              mr: 1,
              "&:hover": {
                background: "#DB2BBB80",
              },
            }}>
            Accept
          </Button>
          <Link href={"/privacy"}>
            <Button
              onClick={handleAccept}
              sx={{
                p: "8px 16px",
                fontWeight: 600,
                fontSize: "11px",
                background: "#DB2BBB",
                color: "#fff",
                mr: 1,
                "&:hover": {
                  background: "#DB2BBB80",
                },
              }}>
              Learn More
            </Button>
          </Link>
        </Box>
      </Box>
    </main>
  );
}
